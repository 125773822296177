import React from "react";
import "./style/Footer.css";
import { Link } from "react-router-dom";
import { Box, Divider, Grid } from "@mui/material";
const Footer = () => {
  return (
    <>
      <Box className="footer_main_container">
        <div className="section">
          <Grid container>
            <Grid
              sx={{ display: "flex" }}
              className="logo-img"
              item
              xl={4}
              lg={4}
              md={4}
              xs={12}
            >
              {/*<img*/}
              {/*  width="150px"*/}
              {/*  src={"/assets/Logo1.png"}*/}
              {/*  alt="Khosla Tech Services"*/}
              {/*/>*/}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-around",
                paddingTop: 2,
              }}
              item
              xl={4}
              lg={4}
              md={4}
              xs={12}
            >
              <div className="link_text_container">
                <div className="link_heading">Company</div>
                <div className="link_text">
                  <Link className="footer_link" to="/">
                    Home
                  </Link>
                  <Link to="/about" className="footer_link">
                    About Us
                  </Link>
                  <Link to="/services" className="footer_link">
                    Services
                  </Link>
                  <Link to="/#team" className="footer_link">
                    Our Team
                  </Link>
                  <Link to="/technologies" className="footer_link">
                    Technologies
                  </Link>
                </div>
              </div>
              <div className="link_text_container">
                <div className="link_heading">Legal</div>
                <div className="link_text">
                  <Link className="footer_link" to="/">
                    Privacy
                  </Link>
                  <Link to="/" className="footer_link">
                    Terms
                  </Link>
                </div>
              </div>
              <div className="link_text_container">
                <div className="link_heading">Resources</div>
                <div className="link_text">
                  <Link className="footer_link" to="/blog">
                    Blog
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid
              // sx={{ display: "flex", justifyContent: "end" }}
              className="rubic"
              item
              xl={4}
              lg={4}
              md={4}
              xs={12}
            >
              {/*<img*/}
              {/*  width="150px"*/}
              {/*  src={"/assets/rubik.gif"}*/}
              {/*  alt="Khosla Tech Services"*/}
              {/*/>*/}
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <Divider sx={{ backgroundColor: "lightgray", mt: 2, mb: 2 }} />
            </Grid>
            <Grid
              sx={{ display: "flex", pt: 1, pb: 1 }}
              className="address"
              item
              xl={6}
              lg={6}
              md={6}
              xs={12}
            >
              <div>
                251 Rocky Vista Cir, NW, Calgary
                <br />
                T3G 5B8 Canada
              </div>
            </Grid>
            <Grid
              sx={{ pt: 1, pb: 1 }}
              className="contact_us"
              item
              xl={6}
              lg={6}
              md={6}
              xs={12}
            >
              <div>
                <div className="contact">
                  <span>
                    <i class="fa-regular fa-envelope"></i>
                  </span>
                  <a
                    href="mailto:info@khoslatechservices.com"
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    info@khoslatechservices.com
                  </a>
                </div>
                <div className="contact contact_phone">
                  <span>
                    <i class="fa-solid fa-phone"></i>
                  </span>
                  <a
                    href="tel:(+1) 807 356 3615"
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    +1 807 356 3615
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <Divider sx={{ backgroundColor: "lightgray", mt: 2, mb: 2 }} />
            </Grid>
            <Grid
              sx={{ display: "flex", pt: 1, pb: 1 }}
              className="copyright_container"
              item
              xl={6}
              lg={6}
              md={6}
              xs={12}
            >
              <div className="copyright_text">
                Copyright © 2023 Khosla Tech Services Inc.
              </div>
            </Grid>
            <Grid
              sx={{ pb: 5, pt: 1 }}
              className="socil_container"
              item
              xl={6}
              lg={6}
              md={6}
              xs={12}
            >
              <div className="social_icon_container">
                <div style={{cursor:"pointer"}}>
                  <i className="fa-brands fa-twitter"></i>
                </div>
                <div  style={{cursor:"pointer"}}>
                  <i className="fa-brands fa-facebook"></i>
                </div>
                <div  style={{cursor:"pointer"}}>
                  <i className="fa-brands fa-linkedin"></i>
                </div>
                <div  style={{cursor:"pointer"}}>
                  <i className="fa-brands fa-instagram"></i>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Footer;
