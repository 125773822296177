import { Card } from '@mui/material'
import React from 'react'
import "./style/blogCard.css"
import { useNavigate } from 'react-router-dom';
const BlogCard = ({blogImag,blogTitle,blogDes,i}) => {
  const navigate = useNavigate();

  return (
  <Card>
      <div
      className="blog-card"
      onClick={() => navigate("/blogDetail", { state: i })}
    >
      <img src={blogImag} alt={blogTitle} className="card-img-top" />
      <div className="card-body">
        <h5 className="card-title">{blogTitle}</h5>
        <p className="card-text">
          {blogDes.length > 90 ? `${blogDes.slice(0, 90)}...` : blogDes}
        </p>
      </div>
  
    </div>
  </Card>
  )
}

export default BlogCard