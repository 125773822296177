import React, { useEffect } from "react";
import "./style/aboutView.css";
import { Box, Grid } from "@mui/material";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_us_main" id="about">
        <Box sx={{ml:5,mr:5}}
         className="about_us_content_section" >
          <div className="content_section">
            <div className="content1">
              <div className="about">
              <h3 >ABOUT US</h3>
              </div>
              <h1>Keep Your Business Safe & Ensure High Availability</h1>
            </div>
            <div className="content2">
              <p>
                A business consulting agency is involved in the planning,
                implementation, and education of businesses. We work directly
                with business owners on developing a business plan, identifying
                marketing needs and developing the necessary skills for business
                ownership.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="hor" />
          </div>
          <Grid
            container
            spacing={4}
            className="about_detail"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid item className="about_grid" xs={12} sm={6} md={2.7}>
              <div className="detail_data">
                <div className="tick">✔</div>
                <div className="commit">Constant Improvement</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="detail_data">
                <div className="tick">✔</div>
                <div className="commit">Commitment to Customers</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={2.8}>
              <div className="detail_data">
                <div className="tick">✔</div>
                <div className="commit">Best Quality You Can Get</div>
              </div>
            </Grid>
            <Grid  item xs={12} sm={6} md={3.5}>
              <div className="detail_data">
                <div className="tick">✔</div>
                <div className="commit">30 Days Money Back Warranty</div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default About;
