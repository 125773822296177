import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./style/blogDetailComponent.css";

const BlogDetailComponent = () => {
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!params) {
    navigate("/blog");
    return null;
  }

  return (
    <div className="blog_detail_main_container">
      <Grid container md={12} xs={12} className="blog_terms_wrapper_left">
        <Grid item md={12} xs={12}>
          <div>
            <div className="blog_img">
              <img width="100%" src={params?.blogImg} alt="img"/>
            </div>
            <div className="title">{params?.title1}</div>
            <div className="desc">{params?.desc1}</div>

            <div className="desc">{params?.titleDes5}</div>
            <div className="desc">{params?.titleDes6}</div>
            <div className="desc">{params?.titleDes7}</div>
            <div className="desc">{params?.titleDes8}</div>
            <div className="desc">{params?.titleDes9}</div>

            {params?.blogImg1 && (
              <div className="blog_img">
                <img width="100%" src={params?.blogImg1} alt="img" />
              </div>
            )}
            {params?.title2 && <div className="title">{params?.title2}</div>}
            {params?.desc2 && <div className="desc">{params?.desc2}</div>}

            {params?.titleDes && <div className="desc">{params?.titleDes}</div>}
            {params?.titleDes1 && (
              <div className="desc">{params?.titleDes1}</div>
            )}
            {params?.title2 && <div className="desc">{params?.titleDes2}</div>}
            {params?.titleDes3 && (
              <div className="desc">{params?.titleDes3}</div>
            )}

            {params?.blogImg2 && (
              <div className="blog_img">
                <img width="100%" src={params?.blogImg2} alt="img" />
              </div>
            )}
            {params?.title3 && <div className="title">{params?.title3}</div>}
            {params?.desc3 && <div className="desc">{params?.desc3}</div>}
            {params?.blogImg3 && (
              <div className="blog_img">
                <img width="100%" src={params?.blogImg3}  alt="img" />
              </div>
            )}
            {params?.title4 && <div className="title">{params?.title4}</div>}
            {params?.desc4 && <div className="desc">{params?.desc4}</div>}
            {params?.blogImg4 && (
              <div className="blog_img">
                <img width="100%" src={params?.blogImg4}  alt="img" />
              </div>
            )}
            {params?.title5 && <div className="title">{params?.title5}</div>}
            {params?.desc5 && <div className="desc">{params?.desc5}</div>}
            {params?.blogImg5 && (
              <div className="blog_img">
                <img width="100%" src={params?.blogImg5} alt="img" />
              </div>
            )}
            {params?.title6 && <div className="title">{params?.title6}</div>}
            {params?.desc6 && <div className="desc">{params?.desc6}</div>}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BlogDetailComponent;
