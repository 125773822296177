import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { HashLink } from "react-router-hash-link";
import "./style/homeLandingView.css";
import { Box } from "@mui/material";
const HomeLandingView = () => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate("/about");
    };
    const [translateY, setTranslateY] = useState(0);
    const itemHeight = 100;
    const items = ["Conceptualize","Create","Construct"];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTranslateY((prevTranslateY) => {
          const nextTranslateY = prevTranslateY - itemHeight;
          return nextTranslateY >= -itemHeight * (items.length - 1)
            ? nextTranslateY
            : 0;
        });
      }, 2000);
      return () => clearInterval(interval);
    }, []);
  return (
    <div className="img_container">
    <div className="black_effect"></div>
    <div className="container_data">
      <div className="margin_left_content">
        <div className="scroll_up_text">
          <div
            className="scroll_content"
            style={{ transform: `translateY(${translateY}px)` }}
          >
            {items.map((text, index) => (
              <div key={index} className="scroll_text">
                {text}
              </div>
            ))}
          </div>
        </div>
        <div className="container_image">
          <img
            style={{ width: "100%", maxWidth: "600px", marginTop: "30px" }}
            src={"/assets/weGet.svg"}
            alt=""
          />
        </div>
        <div>
          <div className="container_des">
            Our team is committed to assisting you throughout the entire product development process, from generating ideas to finalizing the product and launching it. Collaborate with us, and together we can bring your vision to life.
          </div>
        </div>
        <div className="container_btn_container">
          <button onClick={handleClick} className="container_btn">
            About Us
            <FaArrowRight />
          </button>
        </div>
      </div>
      <Box  className="scroll_down_container">
        <HashLink smooth to="/#about" className="scroll_down_arrow">
          <HiOutlineArrowNarrowDown size={50} />
        </HashLink>
      </Box>
    </div>
  </div>  )
}

export default HomeLandingView