import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { blogCardData } from "./BlogDetail/blogCardData";
import "./style/blogCardsContainer.css";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import BlogCard from "./BlogCard";

const BlogCardsContainer = () => {
  const navigation = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsPerPage = 6;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    if (blogCardData?.length >= 1) {
      setCurrentItems(blogCardData?.slice(itemOffset, endOffset));
      setPageCount(Math?.ceil(blogCardData?.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, blogCardData?.length, blogCardData]);

  const handlePageClick = (event) => {
    const newOffSet = (event.selected * itemsPerPage) % blogCardData?.length;
    setItemOffset(newOffSet);
    window.scrollTo({ top: 0 });
  };
  return (
    <>
      <div className="blog_detail_main_container">
        <Grid
          container
          spacing={3}
          style={{ display: "flex", justifyContent: "space-between",  
        }}
        >
          {currentItems.map((i, index) => {
            return (
              <Grid
                onClick={() => navigation("/blogDetail", { state: i })}
                item
                xs={12}
                md={6}
              >
                <BlogCard
                  blogImag={i?.blogImg}
                  blogTitle={i?.title}
                  blogDes={i?.maindesc}
                />
              </Grid>
            );
          })}
        </Grid>

        <div className="blog_btn_main_container">
          <div className="blog_btn_container">
            {/* <button className="blog_btn">Load More</button> */}
            {pageCount > 1 && (
              <ReactPaginate
                nextLabel={<FaAngleRight />}
                previousLabel={<FaAngleLeft />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                breakLabel="..."
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num2"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="active"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCardsContainer;
