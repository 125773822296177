export const blogCardData = [
    {
      blogImg:
        "https://www.bitsbayltd.com/static/media/crypto.2361211260bcd2d8f889.jpg",
      blogImg1:
        "https://www.bitsbayltd.com/static/media/AI.cf21fa23bb2e651bf004.jpg",
      blogImg3:
        "https://www.bitsbayltd.com/static/media/Ability.02f840322ec233db2af8.webp",
      blogImg5:
        "https://www.bitsbayltd.com/static/media/Automated.54464d973d993efba29d.jpg",
      type: "AI-Crypto",
      title: "What can we achieve by merging AI and Cryptocurrency?",
      heading: "What can we achieve...",
      text1: "What can we achieve",
      text2: "by merging AI and",
      text3: "Cryptocurrency?",
      maindesc:
        "Every day we are getting introduced to modern technologies that are fun and help us improve our skills to earn money... ",
      desc: "Every day we are getting introduced to modern technologies that are fun and help us improve our skills to earn money. Isn’t it easy to get all the latest updates by using a platform that will give you access to all the information you need to start a business? There are a lot of articles and news related to AI and cryptocurrency. Most businessmen and sellers already use this high-tech knowledge to gain global recognition and profits. In other words, it can be explained as you investing in something in international shareholders’ markets that come with digital currency and a modern virtual accounting system. AI can also add more magic to it because it has all the modernized features, tools, and advancements that will help you in launching a brand in less time.",
      title1: "How does AI impact cryptocurrency?",
      desc1:
        "AI can be explained as that it has the ability of a digital gadget to do tasks more professionally than a human. It is because it comes with all the technical systems and expert tools that can be more fun to use and help us in doing more work in less time. Furthermore, cryptocurrency is like a digital currency that is an alternative to payments that work through a computing system. That is why AI has an effective impact on cryptocurrency because both need advanced techniques and tricks to get more profits. It can be helpful in decision-making, evaluating different types of data, connecting patterns, and know-how related to the latest trends in technology.",
      title2: "Benefits of merging AI and Cryptocurrency",
      desc2:
        "By merging these two modern techniques you can get a profit that can easily help you to start your own business. Here are some of the benefits we are going to discuss in placing AI and Cryptocurrency together.",
      title3: "1.Ability to Learn",
      desc3:
        "With the use of these modern techniques, you will improve your learning skills and showcase your talent to global marketers. In this way, you will not only be able to start a business but it will also help you to get the attention of well-known investors.",
      title4: "2.Identifying Parties to Strike Deals",
      desc4:
        "It is one of the most important points if you are really into online businesses and trades. With the use of advanced security systems, you can easily identify a real company to invest your money in. Not only that, you can make deals directly and pay a minimal fee.",
      title5: "3.Automated Trading Strategies",
      desc5:
        "A lot of investors and traders are already using these technologies because with the use of AI all the tasks related to an investment can be done more easily. All the work can be done in a single computing device that comes with less error and more professional skills.",
      title6: "4.Risk-free Transactions",
      desc6:
        "With the help of advanced security systems, all transactions can be done more securely between two dealers. It will secure all the information and help you to get rid of malicious users. It will allow the traders to transact more efficiently with zero error.",
    },
    {
      blogImg:
        "https://www.bitsbayltd.com/static/media/Amazontools.964e1dc25dcf9c6711cf.png",
  
      blogImg3:
        "https://www.bitsbayltd.com/static/media/Market.54e7ee597c4b4a063275.png",
  
      blogImg5:
        "https://www.bitsbayltd.com/static/media/pricing.3f89ee8af03a71b6490d.jpg",
  
      blogImg7:
        "https://www.bitsbayltd.com/static/media/Description.807abea27a6a621ea4e1.jpg",
  
      type: "Amazon",
      title: "How Amazon tools are helping seller to grow their Amazon Business?",
      heading: "How Amazon tools are...",
      text1: "How Amazon tools are",
      text2: "helping seller to grow",
      text3: "their Amazon Business?",
      maindesc:
        "In this modern world, everyone wants to do less work with less effort. All thanks to the advanced technologies now it... ",
      desc: "In this modern world, everyone wants to do less work with less effort. All thanks to advanced technologies now it become easier for all of us to showcase our business skills. Back in the past, we were not able to use our skills in the right way to get more profits. But, now we have so many opportunities and strategies that will surely help us to start a business with more income. With just a single click, you will get a list of innovative techniques, and with the help of these modernized appliances, you can start up your online business. While explaining these things how can we forget to mention “Amazon.” No one in the world is not familiar with the word “Amazon” and when you search about it you will able to understand to use your ideas related to business in the right way.",
      title1: "Why are Amazon tools important?",
      desc1:
        "Whoever wants to start a business on Amazon should know about the techniques and tricks related to it. It is because no one is born like a pro everyone needs a helping hand whether it is a person or advanced technology. A lot of people want to know while starting a business why you should need these modernized tools. And the answer is simple, before launching you should make a solid plan and work according to it. These Amazon tools will help you to enhance your skills and abilities. It is like a gateway to the modern world full of opportunities and with the use of these technical techniques you can grow your business with less loss. Amazon is the best marketplace for all the people who are willing to start online work.",
      title2: "Tips to Grow Business on Amazon",
      desc2:
        "If you want to run a successful business then it requires a lot of advanced functionalities all the time to get more customer support. Here are some tips related to Amazon tools that will help you to grow your Amazon business with more proficiency. All you need is to use these techniques professionally and you will be amazed by the results.",
      title3: "1.Focus on the International Market ",
      desc3:
        "It is one the easiest and most accessible ways if you are in thought of starting an Amazon Business. You can simply search for the latest trends and techniques that are required in getting more profit. Not only that, but you can also read reviews related to online companies and their selling items.",
      title4: "2.Expand your Advertising Possibilities",
      desc4:
        "With the help of this policy, you can attract global marketers. By using modern Amazon tools and features you showcase your work and share it with international businesses. It will help you to get more access in less time and will also let you earn more money from it.",
      title5: "3.Improve efficiency in Product and pricing",
      desc5:
        "Before starting a business you should keep in your mind that less is better. The quality of a product comes first before anything because it is the first one going to search. If the reviews are not good then you will not able to get customer support.",
    },
    {
      type: "VR",
      blogImg:
        "https://www.gpstrategies.com/wp-content/webp-express/webp-images/doc-root/wp-content/uploads/2023/02/Blog-Viability-AR-VR-mod_02.04.21-web.jpg.webp",
      title: "Augmented Reality (AR) and Virtual Reality (VR) Technologies",
      heading: "Augmented Reality (AR)...",
      text1: "Augmented Reality (AR) and",
      text2: "Virtual Reality (VR)",
      text3: "Technologies ",
      maindesc:
        "We are living in a modern world where every day we get news and updates related to the latest trends...",
      desc: "We are living in a modern world where every day we get news and updates related to the latest trends happening in the computing world. Not only that but now they are using these technologies to start their business and interact with people around the world. A lot of people love to spend most of their time watching TV, and using computers, and smartphones. This is because these gadgets have a list of all the techniques and features that can be fun to use. With a single touch, a user can easily get the information he wants to know, improve his skills, watch movies, make new friends, and many more. It will be nearly impossible for anyone to make a list of all these modernized tools and systems. But here we are going to discuss one of the two important technologies AR and VR that can be helpful in many ways. Whether you are into business, doing work at the office, or related to any other field with the use of their applications you can enhance your ideas and grow your business growth in less time by doing less work.",
      title2: "What is augmented reality (AR)?",
      desc2:
        "It is a technology that allows a person to see the world with digital transformation. With the use of advanced features and tools, everyone can easily expand their business and start doing the work of their interest. Moreover, it comes with modern techniques that will help a user deal with customers more efficiently and give life to ideas to gain more global attention. In simple words, it can be explained as it is used to create an interactive version of the natural environment with the use of digital elements.It has three exciting features:Combination of digital and physical worlds.Come with user-friendly and real-time interfaces.3D identification of virtual and real objects.",
      title3: "How does augmented reality (AR) work?",
      desc3:
        "Many people are already getting benefits by using this technology and experiencing different types of features. It has completely changed our mindset and thinking related to business management. Furthermore, it let a user showcase his skills to international marketers to get more profits. Some components will help a person to know how this technology is going to work in real-time interaction.Artificial intelligence is allowing users to complete actions using voice prompts. AI can also be helpful in processing information for your AR application.AR software is an application used to access AR. A lot of businesses can be created their form of AR software.Processing: Users will need processing power for their technology to work, by supporting their device’s internal operating system.Lenses: A user needs a lens to view images. The better quality of the screen is, the more clear-sighted image will appear.Sensors: Whenever the camera of a gadget captures information it helps to send that data directly to software for processing.",
      title4: "Top Applications of augmented reality (AR)",
      desc4:
        "Here comes one of the top applications of augmented reality that will help you to improve your learning skills and will let you explore the modern world full of the latest technologies and trends.Medical Education and Training AR helps medical organizations by providing all-important information related to medicines where lab workers can observe and understand how the drug is going to work in the human body through a three-dimensional version.Gaming and Entertainment Sector AR adds new magic to the entertainment and gaming world by allowing viewers to become active participants in a show by creating outland worlds, engaging the audience in the plot, and providing additional information.Design, Branding, and Modelling The use of augmented reality design enables us to fully transfer a concept from the screen into the physical environment. Walking around or within something allows us to understand better its shape, size, and relationship to the surrounding area.Tourism Business AR can make traveling more enjoyable and memorable. Additionally, location-based features allow for the provision of a better experience that is tailored to a user’s interests and demands. ",
      title5: "What is virtual reality (VR)?",
      desc5:
        "A modern technology allows a user to rearrange computer images and videos to add real-life visual experiences that will be more fun to use. It comes with a list of 3D graphical effects, adjustments, spectacular filters, animation, and many more advanced features to create images that have a sense of spatial presence. Additionally, it can make a user feel like he is dealing with things that are completely changed from the real world. But in reality, it is just a computing tool to create effects in 3D.",
    },
  
  ];
  