import { Grid } from "@mui/material";
import React from "react";
import "./style/blogView.css";
import BlogCard from "../components/BlogCard";
import { blogCardData } from "../dummyData/blogCardData";
import { useNavigate } from "react-router-dom";
const BlogView = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/blogViewPost");
  };
  return (
    <Grid sx={{ pl: 4, pr: 4,pt:4 }} container id="blog" clas>
      <Grid item md={12} lg={12} xl={12} sm={12}>
        <div className="blog_text">
          <h1>Blogs</h1>
        </div>
        <div className="blog_content">
          <div className="t_content_1">
            <h1>Khosla on what we think</h1>
          </div>
          <div className="t_content">
            <p>Our latest musings on the world of Technology & Design.</p>
          </div>
        </div>
      </Grid>
      <Grid container spacing={3} style={{display:"flex",justifyContent:"space-between"}}>
        {blogCardData.map((i, index) => {
          return (
            <Grid item xl={3.5} lg={3.5} md={3} sm={12}>
              <BlogCard
                blogImag={i?.blogImg}
                blogTitle={i?.title}
                blogDes={i?.maindesc}
                i={i}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item md={12} lg={12} xl={12} sm={12} className="blog_home_btn_container">
          <button onClick={handleClick} className="blog_home_btn">
            View All Posts
          </button>
        </Grid>
    </Grid>
  );
};

export default BlogView;
