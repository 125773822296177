import React, { useEffect, useState } from "react";
import { FaGripLines } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import "./style/navbar.css";

const Navbar = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showFixedMenuButton, setShowFixedMenuButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > window.innerHeight;
      setIsScrolled(scrolled);

      // Check if the user scrolled down 100vh
      setShowFixedMenuButton(window.scrollY >= window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === "/";

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar_container">
      <div
        className={`navbar ${isScrolled ? "scrolled" : ""} ${
          isHomePage ? "home" : "other-page"
        }`}
      >
        <div className="nav_data">
          <a href="/" className="nav_logo">
            <img
              width="200px"
              style={{ minWidth: "40px" }}
              src={"/assets/Logo1.png"}
              alt="Khosla Tech Services"
            />
          </a>
          <div className="nav_right_container">
            <div className="nav_links">
              <HashLink smooth to="/#service" className="nav_link">
                SERVICES
              </HashLink>
              <HashLink smooth to="/#about" className="nav_link">
                ABOUT
              </HashLink>

              <Link to="/technologies" className="nav_link">
                TECHNOLOGIES
              </Link>
              <HashLink smooth to="/#blog" className="nav_link">
                BLOG
              </HashLink>
              <HashLink smooth to="/#contact" className="nav_link">
                CONTACT
              </HashLink>
            </div>
            <div
              className={`nav_menu_btn ${showFixedMenuButton ? "fixed" : ""}`}
              onClick={toggleMenu}
            >
              <FaGripLines />
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="fullscreen-menu">
            <div className="nav_menu_links">
              <HashLink
                smooth
                to="/"
                className="nav_menu_link"
                onClick={toggleMenu}
              >
                Home
              </HashLink>
              <HashLink
                smooth
                to="/services"
                className="nav_menu_link"
                onClick={toggleMenu}
              >
                SERVICES
              </HashLink>
              <HashLink
                smooth
                to="/about"
                className="nav_menu_link"
                onClick={toggleMenu}
              >
                ABOUT
              </HashLink>
              <Link
                to="/technologies"
                className="nav_menu_link"
                onClick={toggleMenu}
              >
                TECHNOLOGIES
              </Link>
              <HashLink
                smooth
                to="/blog"
                className="nav_menu_link"
                onClick={toggleMenu}
              >
                BLOG
              </HashLink>
              <HashLink
                smooth
                to="/contact"
                className="nav_menu_link"
                onClick={toggleMenu}
              >
                Contact
              </HashLink>
            </div>
            <div className="nav_menu_contact_info">
              <div className="nav_menu_address">
                <p>251 Rocky Vista Cir, NW, Calgary</p>
                <p>T3G 5B8 Canada</p>
              </div>
              <div className="nav_menu_contect">
                <p>(+1)-807 356 3615</p>
                <p>info@khoslatechservices.com</p>
              </div>
              <div className="nav_menu_manufacture">
                Copyright © 2023 Khosla Tech Services Inc.
              </div>
            </div>
            <div className="close-menu-btn" onClick={toggleMenu}>
              <IoMdClose />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
