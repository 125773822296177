import React from "react";
import "./style/blogCategorie.css";
import { useState } from "react";
import { useEffect } from "react";
import { blogCardData } from "./BlogDetail/blogCardData";
import { useNavigate } from "react-router-dom";

const BlogCategorie = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsPerPage = 4;
  const navigation = useNavigate();
  useEffect(() => {
    const totalItems = blogCardData?.length || 0;
    const startIndex = Math.max(totalItems - itemOffset - itemsPerPage, 0);
    const endIndex = Math.max(totalItems - itemOffset, 0);

    if (totalItems >= 1) {
      setCurrentItems(blogCardData?.slice(startIndex, endIndex));
    }
  }, [itemOffset, itemsPerPage, blogCardData?.length, blogCardData]);

  return (
    <div className="blog_categorie">

      <div className="Posts">
        <div className="post-heading">Recent posts</div>
        {currentItems.map((i, index) => (
          <div
            onClick={() => navigation("/blogDetail", { state: i })}
            className="post-1"
          >
            <div className="post_img_container">
              <img className="Post1-img" src={i.blogImg} alt="img" />
            </div>
            <div className="Post1-text">{i.title}</div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default BlogCategorie;
