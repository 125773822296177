import React from "react";
import "./style/TechCard.css";
import { Grid } from "@mui/material";

const TechCard = ({ data, title }) => {
  const Data = data();
  return (
    <>
      <Grid container spacing={4} sx={{ pl: 2,mb:2,mt:2,display:"flex",justifyContent:"space-around",}} rowGap={2}>
        {Data.map((item, index) => (
          <div className="technolgy_box">
            <img src={item.pic} width="100%" height={"100%"} alt="img" />
          </div>
        ))}
      </Grid>
    </>
  );
};

export default TechCard;
