import { Box, Grid } from "@mui/material";
import React from "react";
import "./style/TechnologiesLandingView.css"

const TechnologiesLandingView = () => {
  return (
    <>
      <div className="tech_container">
        <Box id="head_container" >
          <div className="head_data">
            <div className="main_heading">Serving Better Technology</div>
            <div className="head_img">
              <img src={"/assets/tech.png"} alt={"img"} />
            </div>
          </div>
        </Box>
        <Grid item xl={3.8} lg={3.8} md={3.8} xs={12}>
          <div className="techcontentBox">
            <div className="techContent1">
              <h1 sx={{ color: "#060606" }}>Technologies</h1>
            </div>
            <div className="techContent2">
              <p sx={{ color: "#706c6c" }}>
                A growing team of technologists- we have earned a reputation in
                the small & medium sized companies with deep understanding of different<br/>
                Programming languages and tools in the areas of design,
                development, and testing. Certified and experienced, our team
                combines technical <br /> know-how with industry best practices
                to create viable solutions.
              </p>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default TechnologiesLandingView;
