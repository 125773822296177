import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home.jsx";
import Navbar from "./components/Navbar.jsx";
import Technologies from "./pages/Technologies.jsx";
import Footer from "./components/Footer.jsx";
import BlogDetail from "./ContentComponent/BlogDetail.jsx";
import BlogViewPost from "./ContentComponent/BlogViewPost.jsx";
import Services from "./pages/Services.jsx";
import Contact from "./ContentComponent/ContactView.jsx";
import About from "./ContentComponent/AboutView.jsx";
import BlogView from "./ContentComponent/BlogView.jsx";
import ServicesCard from "./ContentComponent/ServicesCard.jsx";

function App() {
  return (
    <div className="app">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service" element={<ServicesCard />} />
          <Route path="/about" element={<About />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/blog" element={<BlogView />} />
          <Route path="/blogViewPost" element={<BlogViewPost />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogDetail" element={<BlogDetail />} /> 
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
