import React from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import "./style/blogDetail.css";
import BlogDetailComponent from "../components/BlogDetailComponent";
import BlogCategorie from "../components/BlogCategorie";

const BlogDetail = () => {
    const location = useLocation();
    const params = location.state;
  return (
    <div className="blog_detail_main">
        <div className="blog_detail_text">
          <h1>{params?.title}</h1>
        </div>
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
      }}
    >
      <div className="blog_detail_data_container" >
        <Grid container spacing={4}  >
          <Grid item xl={8.5} lg={8.5} md={8.5} sm={12}>
            <BlogDetailComponent />
          </Grid>
          <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
            <BlogCategorie />
          </Grid>
        </Grid>
        {/* <BlogReply /> */}
      </div>
    </div>
  </div>
  )
}

export default BlogDetail