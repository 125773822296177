import React from "react";
import TechTypes from "../components/TechTypes";
import TechnologiesLandingView from "../ContentComponent/TechnologiesLandingView";
const Technologies = () => {
  return (
    <div>
      <TechnologiesLandingView />
      <TechTypes />
    </div>
  );
};

export default Technologies;
