import { Card } from "@mui/material";
import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import "./style/homeCard.css";
import { Link } from "react-router-dom";

const HomeCard = ({
  Icon,
  Heading,
  Description,
  CardArrow,
  link,
}) => {
  if (link && link.type === "email") {
    Description = (
      <span className="info">
        Info: 
        {" "} 
      <a
         className="crad-para"
        href={`mailto:${link.value}`}
        rel="noopener noreferrer"
      >
        {link.label}
      </a>
      </span>
    );
  } else if (link && link.type === "phone") {
    Description = (
      <span className="info">
        Mobile: 
        {" "} 
        <a
         className="crad-para"
          href={`tel:${link.value}`}
          rel="noopener noreferrer"
        >
          {link.label}
        </a>
      </span>
    );
  } else if (link && link.type === "Location"){
    Description = (
      <div className="info">
        Location:
        {" "}
      <span
       className="crad-para2"
      >
        {link.value}
      </span>
      </div>
    );
  }
  return (
    <Card elevation={4} sx={{ pb: 2, pt: 2, pl: 2, pr: 2 }}>
      <div className="card_img">
        <img src={Icon} alt="" />
      </div>
      <div className="card-heading">
        <h3>{Heading}</h3>
      </div>
      <div
        style={{
          fontSize: 20,
          color: "#6c6c79",
          lineHeight: 1.7,
        }}
      >
        {Description}
      </div>
      {CardArrow && (
        <div className="card_arrow">
          <div className="icon">
            <FaArrowRightLong />
          </div>
          <div className="iconLink">
            <Link className="link" to="/services">
              Read More
            </Link>
          </div>
        </div>
      )}
    </Card>
  );
};

export default HomeCard;
