// Contact.js
import React from "react";
import "./style/ContactView.css";
import HomeCard from "../components/HomeCard";
import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from 'emailjs-com';

const Contact = ({ id }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
    },
  });
 
  const handleSubmit = async (values) => {
    emailjs.send("service_geldb9c","template_t4jgl44",{

      name:values.name,
      email: values.email,
      message: values.message,
      },
      "wQ3V5p8rh4VB2jrlo"
      );
  };
  return (
    <div className="contact_main_container" id="contact">
      <div className="contact-section">
        <div className="contact-container">
          <Box sx={{ pl: 4, pr: 4 }}>
            <div className="contact-title">
              <h1>CONTACTS</h1>
            </div>
            <div className="contact-heading">
              <h2>Let’s make something amazing together.</h2>
            </div>
          </Box>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
            spacing={3}
            sx={{
              pl: 4,
              pr: 4,
            }}
          >
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} xs={12}>
              <HomeCard
                Icon={"/assets/icon1.png"}
                Description="251 Rocky Vista Cir, "
                Heading="HQ"
                link={{
                  type: 'Location',
                  value: '251 Rocky Vista Cir, NW, Calgary T3G 5B8, AB',
                  label: '251 Rocky Vista Cir, NW, Calgary T3G 5B8 AB',
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} xs={12}>
              <HomeCard
                Icon={"/assets/icon2.png"}
                Description="Mobile: (+1) 807 356 3615"
                Heading="Phone no."
                link={{
                  type: 'phone',
                  value: '+18073563615',
                  label: '(+1) 807 356 3615',
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} xs={12}>
              <HomeCard
                Icon={"/assets/icon3.png"}
                Description="Info: info@khoslatechservices.com"
                Heading="E-mail"
                link={{
                  type: 'email',
                  value: 'info@khoslatechservices.com',
                  label: 'info@khoslatechservices.com',
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className="contact-container-2">
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(formik.values); }}>
            <Box sx={{ pl: 4, pr: 4 }}>
              <div className="heading-setion">
                <div className="heading-2">You Can Write Us</div>
                <div className="paragraph">
                  <p>
                    If you have an idea, want to collaborate, or need help
                    designing or developing products, please contact us and we
                    will get back to you soon.
                  </p>
                </div>
              </div>
              <div className="input-section">
                <div className="input-1">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="error-message">{formik.errors.name}</div>
                ) : null}
                <div className="input-1">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="email_input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error-message">{formik.errors.email}</div>
                ) : null}
                <div>
                  <textarea
                    name="message"
                    className="text-area"
                    placeholder="Message"
                    cols={102}
                    rows={8}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                </div>
                {formik.touched.message && formik.errors.message ? (
                  <div className="error-message">{formik.errors.message}</div>
                ) : null}
              </div>
              <div className="contact_btn">
                <button type="submit">Send Message</button>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
