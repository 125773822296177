import React from "react";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import "./style/scrollToTop.css"

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  };
  return (
    <div className="scroll_main_container">
      <div className="scroll_to_top_button" onClick={scrollToTop}>
        <HiOutlineArrowNarrowUp />
      </div>
    </div>
  );
};

export default ScrollToTop;
