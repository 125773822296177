import React, { useEffect } from "react";
import ServicesCard from "../ContentComponent/ServicesCard";
import { Grid } from "@mui/material";
import "./style/services.css"

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       <Grid item md={12} lg={12} xl={12} sm={12} sx={{pt:12,pl:4,pr:4,}} >
        <div className="service_text">
          <h1>Our Services</h1>
        </div>
          <div className="service_content">
            <p>We believe in the power of design and technology.</p>
          </div>
      </Grid>
      <ServicesCard />
    </>
  );
};

export default Services;
