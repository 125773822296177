import React from "react";
import "./style/ServicesCard.css";
import { Card, Grid } from "@mui/material";
const ServiceCard = ({ title, Back, colorP, colorh, backText,des }) => {
  return (
    <Card
      sx={{
        bgcolor: Back,
      }}
      className="service-card"
    >
      <div className="service-card-title">
        <h3 style={{ color: colorh,fontSize:30,marginBottom:10 }}>{title}</h3>
      </div>
      <div className="service-card-para">
        <p style={{ color: colorP }}>{des}</p>
      </div>
      <div className="background-text">{backText}</div>
    </Card>
  );
};
const ServicesCard = () => {
  return (
    <Grid container sx={{ pl: 4, pr: 4,pb:4 }} className="service-card-container" id="service">
      <Grid item container className="service-container"  rowGap={3}>
        <Grid
          item
          xs={12}
          md={3}
          lg={3}
        >
          <ServiceCard
            Back="#f8f8f8"
            colorP="gray"
            colorh="Black"
            title={"How It Work?"}
            des={"Explaining processes, mechanisms, or systems in a concise and comprehensible manner."}
          />
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <ServiceCard
            Back="#0f4c81"
            colorP="white"
            colorh="white"
            title={"Business Analysis"}
            des={
              "Analyzing operations, systems, and data to enhance efficiency and drive organizational improvements."
            }
            backText="01"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ServiceCard
            Back="#f8f8f8"
            colorP="#666"
            colorh="black"
            title={"Business Planning"}
            des={
              "Guiding individuals or organizations in creating strategies to achieve goals and success."
            }
            backText="02"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ServiceCard
            Back="#000"
            colorP="white"
            colorh="white"
            title={"Business Growth"}
            des={
              "Stimulating and expanding business activities to achieve increased revenue, market share, and success."
            }
            backText="03"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicesCard;
