import React, { useEffect } from "react";
import "./style/blogViewPost.css";
import { Box, Grid } from "@mui/material";
import BlogCategorie from "../components/BlogCategorie";
import BlogCardsContainer from "../components/BlogCardsContainer";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box  className="blog_container" id="blog">
      <Grid item md={12} lg={12} xl={12} sm={12} sx={{ pl: 4, pr: 4 }}>
        <div className="blog_view_text">
          <h1>Our Blogs</h1>
        </div>
        <div className="blog_content">
          <div className="t_content">
            <h1>Khosla on what we think</h1>
          </div>
          <div className="t_content">
            <p>Our latest musings on the world of Technology & Design.</p>
          </div>
        </div>
      </Grid>
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <div className="blog_detail_data_container">
            <Grid container spacing={4} sx={{ pl: 4, pr: 4 }}>
            <Grid item xl={8.5} lg={8.5} md={8.5} sm={12}>
                <BlogCardsContainer />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} >
                <BlogCategorie />
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Blog;
